<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-text>
        <filterComponents
          @doFilter="setFilter"
        />
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        {{ $t('savebox.saveBox') }}
        <v-spacer />
        <v-btn
          v-if="this.$route.name === 'saveboxReport'"
          outlined
          class="mx-2"
          color="primary"
          @click="exportExel()"
        >
          {{ $t('actions.print') }}
        </v-btn>
      </v-card-title>
      <v-data-table
        :loading="dataLoading"
        :headers="headers"
        :search="search"
        :items="list"
        :items-per-page="20"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :options.sync="options"
        :server-items-length="total"
        :page-count="numberOfPages"
        @fetchAllItems="fetchAllItems"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            v-if="item.status === 'In'"
            color="success"
            text-color="white"
          >
            {{ item.status }}
          </v-chip>
          <v-chip
            v-else
            color="red"
            text-color="white"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <template
          v-slot:no-data
          loading
        >
          <p>{{ $t('actions.noData') }}</p>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
  import filterComponents from './filter.vue'
  import { ServiceFactory } from '@/services/ServiceFactory'
  const saveboxService = ServiceFactory.get('savebox')
  export default {
    name: 'Companies',
    components: {
      filterComponents,
    },
    data: (vm) => ({
      search: '',
      dataLoading: false,
      page: 0,
      total: 0,
      numberOfPages: 0,
      options: {},
      list: [],
      Roles: [],
      filter: {
        branch_id: null,
        status: null,
        from_date: null,
        to_date: null,
        payment_method: null,
        client_id: null,
      },
      loading: false,
      deleteDailog: false,
      itemDetails: {},
      editedIndex: -1,
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 3000,
      successMessage: '',
      errorMessage: '',
      disabled: false,
      header: ['ID', 'client', 'date', 'branch', 'payment', 'status', 'type', 'reason'],
      headers: [
        {
          text: vm.$t('settings.id'),
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: vm.$t('savebox.client'), sortable: false, value: 'employee.name' },
        { text: vm.$t('savebox.date'), sortable: false, value: 'created' },
        { text: vm.$t('savebox.branch'), sortable: false, value: 'branches.name' },
        { text: vm.$t('savebox.payment'), sortable: false, value: 'amount' },
        { text: vm.$t('savebox.status'), sortable: false, value: 'status' },
        { text: vm.$t('savebox.type'), sortable: false, value: 'type' },
        { text: vm.$t('savebox.reason'), sortable: false, value: 'reason' },
        // { text: vm.$t('actions.actions'), value: 'actions', sortable: false },
      ],
      bookType: 'xlsx',
      autoWidth: true,
      filename: 'savebox Report',
    }),
    watch: {
      options: {
        handler () {
          this.fetchAllItems()
        },
      },
    },
    methods: {
      setFilter (value) {
        this.filter = value
        this.fetchAllItems()
      },
      async fetchAllItems () {
        this.dataLoading = true
        const { page, itemsPerPage } = this.options
        const pageNumber = page - 1
        const list = await saveboxService.getAllItems(itemsPerPage, page, pageNumber, this.filter)
        this.list = list.data
        this.total = list.total
        // this.numberOfPages = companies.data.pageCount
        this.dataLoading = false
      },
      async exportExel () {
        this.loading = true
        const dataList = await saveboxService.getAllItems('all', 1)
        import('@/vendor/Export2Excel').then(excel => {
        const tHeader = this.header
        const list = dataList.data.map(item => {
          return {
            id: item.id,
            client: item.employee.name,
            created: item.created,
            branches: item.branches.name,
            amount: item.amount,
            status: item.status,
            type: item.type,
            reason: item.reason,
            }
        })
        const data = this.formatJson(list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename,
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        })
        this.loading = false
      })
      },
      formatJson (jsonData) {
        return jsonData.map(v => {
          return Object.values(v)
        })
      },
    },
  }
</script>
<style>
a{
  text-decoration: none;
}
</style>
